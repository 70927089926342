import { Motion, spring } from 'react-motion'
import React, { useState, useEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

let observer
const listeners = new WeakMap()

const getObserver = () => {
  if (!observer) {
    observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        listeners.get(entry.target)()
      }
    })
  }
  return observer
}

const observe = (el, cb) => {
  const observer = getObserver()
  observer.observe(el)
  listeners.set(el, cb)
  return () => {
    observer.unobserve(el)
    listeners.delete(el)
  }
}

const HeadlessAccordion = ({ open, children }) => {
  const [height, setHeight] = useState(0)
  const ref = useRef()

  useEffect(
    () =>
      observe(ref.current, () =>
        setHeight(open ? ref.current.offsetHeight : 0)
      ),
    [open]
  )

  return (
    <Motion style={{ height: spring(height) }}>
      {styles => (
        <div style={{ ...styles, overflow: 'hidden' }}>
          <div ref={ref}>{children}</div>
        </div>
      )}
    </Motion>
  )
}

export default HeadlessAccordion
