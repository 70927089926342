import Img from 'gatsby-image'
import moment from 'moment'
import React, { useState } from 'react'

import HeadlessAccordion from './HeadlessAccordion'
import Image from './Image'
import markdown from '../utils/markdown'
import styles from './PublicationItem.module.css'
import stylesImage from './PublicationImage.module.css'

const PublicationItem = ({ node, projects }) => {
  const [open, setOpen] = useState(false)

  const projectMap = {}
  for (const project of projects) {
    projectMap[project.name] = [project.slug]
  }

  return (
    <article className={styles.PublicationItem} onTouchStart={() => {}}>
      <div className={styles.PublicationItem_Cell}>
        <figure>
          <Img
            fixed={node.image.childImageSharp.fixed}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ height: '100%', width: '100%', objectFit: 'contain' }}
          />
        </figure>
        <div className={styles.PublicationItem_Info}>
          <h1>{node.name}</h1>
          <div className={styles.PublicationItem_Note}>
            <div>
              <div>{moment(node.date).format('YYYY')}</div>
              {node.note && <div>{node.note}</div>}
            </div>
            {node.description || (node.projects && node.projects.length) ? (
              <div>
                {node.description && (
                  <div className={styles.PublicationItem_Description}>
                    {markdown(node.description)}
                  </div>
                )}
                {node.projects && node.projects.length ? (
                  <ul>
                    {node.projects.map((project, i) => (
                      <li key={i}>
                        {project in projectMap ? (
                          <a href={`/#${projectMap[project]}`}>{project}</a>
                        ) : (
                          project
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {node.link ? (
          <a href={node.link} target="_blank" rel="noopener noreferrer">
            Open link
          </a>
        ) : (
          <></>
        )}
        {node.images && node.images.length ? (
          <button
            className={open ? styles.isOpen : styles.isClosed}
            onClick={() => setOpen(open => !open)}
            aria-label="Open"
          />
        ) : (
          <></>
        )}
      </div>
      {node.images && node.images.length ? (
        <HeadlessAccordion open={open}>
          <ul className={styles.PublicationItem_ImageList}>
            {node.images.map((image, i) => (
              <li key={i}>
                <Image
                  fluid={image.image.childImageSharp.fluid}
                  className={stylesImage.PublicationImage}
                  classNameGhost={stylesImage.PublicationImage_Ghost}
                  classNamePlaceholder={
                    stylesImage.PublicationImage_Placeholder
                  }
                />
              </li>
            ))}
          </ul>
        </HeadlessAccordion>
      ) : (
        <></>
      )}
    </article>
  )
}

export default PublicationItem
