import { Helmet } from 'react-helmet'
import React from 'react'
import { graphql } from 'gatsby'

import DocumentContainer from '../components/DocumentContainer'
import Layout from '../components/Layout'
import PublicationItem from '../components/PublicationItem'
import Section from '../components/Section'

export default ({ data, location }) => {
  const projects = data.allProjectsYaml.edges.map(({ node }) => node)
  return (
    <Layout location={location}>
      <Helmet title="Publications" />
      <DocumentContainer>
        <Section title="Publications">
          {data.allPublicationsYaml.edges.map(({ node }) => (
            <PublicationItem
              node={node}
              projects={projects}
              key={node.fields.slug}
            />
          ))}
        </Section>
      </DocumentContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    allPublicationsYaml(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          name
          date
          note
          description
          projects
          link
          image {
            childImageSharp {
              fixed(height: 100, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 502, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    allProjectsYaml {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
